'use client'
import type { FC } from 'react'
import { useState } from 'react'
import type { OldProductDTO, ProductLightDTO } from 'ecosystem'
import type { ContactFormType, IContactUsFormProps, IContactUsSimpleFormProps } from 'forms'
import { ContactUsForm, ContactUsSimpleForm } from 'forms'
import useGenericRequest from 'api/utils/next/useGenericRequest'
import { prettifyFileSize, VERCEL_UPLOAD_MAX_SIZE } from 'shared-utils'

export interface IContactPureProps
  extends Omit<IContactUsFormProps, 'error' | 'onFormSubmit' | 'data'> {
  apiPath: string
  product?: OldProductDTO | ProductLightDTO
  onSuccess?: (data: Partial<ContactFormType>) => void
  formVariant?: 'SIMPLE' | 'DEFAULT'
}

const ContactPure: FC<IContactPureProps> = ({
  apiPath,
  product,
  withDropZone,
  customDescriptionPlaceholder,
  onSuccess,
  submitButtonProps,
  formVariant = 'DEFAULT',
  ...props
}) => {
  const [error, setError] = useState<string>('')
  const { fetchNextRoute } = useGenericRequest()

  const handleFormSubmit = async (contact: Partial<ContactFormType>, attachments: File[]) => {
    setError('')

    const formData = new FormData()
    formData.append('contact', JSON.stringify(contact))

    let totalFilesSize = 0

    if (attachments.length) {
      attachments.forEach((attachment) => {
        totalFilesSize += attachment.size
        formData.append('attachments', attachment, attachment.name)
      })
    }

    if (totalFilesSize > VERCEL_UPLOAD_MAX_SIZE) {
      const textError = `Total file size is more than ${prettifyFileSize(VERCEL_UPLOAD_MAX_SIZE)}.`
      setError(textError)

      throw new Error(textError)
    }

    await fetchNextRoute(apiPath, {
      method: 'POST',
      body: formData
    })
      .unwrap()
      .then(() => {
        onSuccess?.(contact)
      })
      .catch((e) => {
        setError(e instanceof Error ? e.message : (e as string))
        throw e
      })
  }

  const commonProps: IContactUsSimpleFormProps = {
    mx: 'auto',
    onFormSubmit: handleFormSubmit,
    w: { base: '100%', sm: '80%', md: '60%', lg: '50%' },
    ...{ withDropZone, customDescriptionPlaceholder, error, submitButtonProps },
    ...props
  }

  switch (formVariant) {
    case 'SIMPLE':
      return <ContactUsSimpleForm {...commonProps} />

    default:
      return <ContactUsForm data={product} {...commonProps} />
  }
}

export default ContactPure
